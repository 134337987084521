import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
    369: '0xCF5c8e4b2fD32700d411C5C261e11D69F1F5DD31',
    11451: '0xB22Eff29C2Fff4eFff12283702BC15EB7f1133aC',
  },
  presale: {
    97: '',
    56: '0xd874fe63e7bd6fa2cda04d3e4a304a1b7e2152c3',
    1: '0xd874fe63e7bd6fa2cda04d3e4a304a1b7e2152c3',
    369: '0xA8109AaDa855f906BdA4035931A57c83944337C2',
    11451: ''
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    369: '0xCF5c8e4b2fD32700d411C5C261e11D69F1F5DD31',
    11451: '0xB22Eff29C2Fff4eFff12283702BC15EB7f1133aC',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    369: '',
    11451: ''
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    369: '0x7291a375D3498980d94d1649C0249B795E950505',
    11451: ''
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    369: '',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11451: ''
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    369: '',
    97: '',
    11451: ''
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    369: '',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    11451: ''
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    369: '',
    97: '',
    11451: ''
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    369: '',
    97: '',
    11451: ''
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    369: '',
    97: '',
    11451: ''
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    369: '',
    97: '',
    11451: ''
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    369: '',
    97: '',
    11451: ''
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    369: '',
    97: '',
    11451: ''
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    369: '',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    11451: ''
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    369: '',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    11451: ''
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    369: '0x703e35AF0944ed7fB7da6aCdC595Da759968993F',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    11451: ''
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    369: '0x627ee38bfFE94692A11Bdd0B036c02b7fc2acd45',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    11451: ''
  },
  lendX: {
    56: '',
    97: '',
    369: '0x8a667552983c5Cd6B5216A9bF577C524C6000099',
    11451: ''
  },
  referral: {
    369: "0x8132A789a874464C60a8BeB322B933Fc898dbcD6",
    97: "",
    56: "",
    11451: '0x8132A789a874464C60a8BeB322B933Fc898dbcD6'
  },  
}